//mobile phone screen rules

@media screen 
	and (max-device-width: 770px)
	{

	.App{
		max-width: 100vw!important;
	}

	.logotext{
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		text-align: center;
		font-size: 2rem!important;
	}

	.sublogotext{
		font-size: 1.25rem!important;
	}

	.buttoncustom{
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		font-size: 14px!important;
	}

	.subtitle{
		font-size: 1.5rem!important;
		margin-top: 1rem!important;
		margin-bottom: 1rem!important;
	}

	//header and displayjumbotron
	.Header{
		.headercontent{
			display: none!important;
		}
	}

	.DisplayJumbotron{
		max-height: 300px!important;
	}
	
	.secondrow {
		padding-left: 0.5rem!important;
		padding-right: 0.5rem!important;
	}

	.jumbolinetext{
		display: none;
	}

	.lefttext{
		margin-right: 0rem!important;
	}
	
	//home page
	.mainbody{

		.paragraph1{
			font-size: 1rem!important;
			padding-left: 1rem!important;
			padding-right: 1rem!important;
		}

		.notescontainer{
			display: flex!important;
			flex-direction: column!important;
			align-items: center;
			justify-content: center!important;
			padding: 0!important;
			margin: 0!important;
			max-width: 100vw!important;

			.image2{	
				width: 300px;

				&:hover{
					transform: none;
				}
			}

			.notes{
				width: 300px;
			}
		}

		.testimonies{
			margin-left: -0.5rem!important;

			.carousel-indicator{
				display: none!important;
			}

			.carousel-control-prev{
				display: none!important;
			}

			.sr-only{
				display: none!important;
			}

			.carousel-control-next{
				display: none!important;
			}

			.carousel-inner{
				margin-left: 0!important;
			}

			.card{ 
				width: 78vw!important;
				min-height: 25vh!important;
			}

			.card-text{
				width: 78vw!important;
				font-size: 15px!important;
			}
		}

		.rooms{
			.roomcontainer{
				display: flex!important;
				flex-direction: column!important;
			}

			.sliderimage{
				height: 35vh;
			}

			.slider{
				width: 85vw!important;
			}

			.paragraph2{
				padding-top: 2rem!important;
				width: 85vw!important;
				font-size: 1rem!important;
			}
		}

		.tourism{
			display: flex;
			flex-direction: column;
			margin-top: 1rem!important;

			.TourismSlider{
				margin-top: 0!important;

				.row2{
					display: flex;
					flex-direction: column;
					justify-content: center!important;
					--bs-gutter-x: none!important;
					max-width: none!important;
				}

				.imageleft{
					margin: 0!important;
					padding: 0!important;
					max-width: 85vw;
					
					&:hover{
						transform: none!important;
					}
				}

				.paragraph3{
					height: auto;
					font-size: 1rem!important;
					width: 85vw!important;
					padding-top: 4rem;
					padding-left: 2rem;
					padding-right: 2rem;
					padding-bottom: 2rem;
				}

				.responsiveparagraph3{
					text-align: justify!important;
				}

				.responsivebutton{
					text-align: center!important;
				}
			}
		}
	}

	//booking page
	.Booking{
		.cardbooking{
			margin-top: 1.5rem;
			margin-left: 1.5rem;
		}
		.calendar{
			width: 90vw!important;
			height: 45vh!important;
		}
	}

	//contacts page
	.Contacts{
		.responsiveprofile{
			margin-left: 0.5rem;
			justify-content: center!important;
		}

		.profiletitle{
			text-align: center;
		}

		.responsivetext{
			text-align: right;
		}

		.responsivetag{
			margin-left: 1rem;
			justify-content: flex-start!important;
		}

		.responsiveend{
			margin-bottom: 3rem;
		}
	}


	//footer
	.Footer{
		font-size: 1rem;

		.footerlogo{
			font-size: 2rem!important;
		}

		.middlecolfooter{
			border-left: none!important;
			border-right: none!important;
		}

		.columnfooter{
			margin-bottom: 2rem!important;
		}

		.responsivefooter{
			margin-top: 2rem!important;
		}

		.responsivehidden{
			display: none!important;
		}

		.responsivedisplayed{
			display: initial!important;
		}

		.responsivetitle{
			font-size: 1.5rem!important;
		}

		.responsivemail{
			font-size: 1.2rem!important;
		}

		.responsivephone{
			font-size: 1.2rem!important;
		}
	}
}