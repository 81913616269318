// tablet screen rules

@media screen
    and (min-device-width: 751px)
    and (max-device-width: 1000px)
{
    
    //header

    .Header a span{
        display: none;
    }

    .logotext{
        font-size: 3rem!important;
    }

    .phonelink{
        display: none;
    }

    .icons{
        margin-top: 0rem!important;
        margin-left: 2rem!important;
    }

    .jumbolinetext{
        display: none;
    }

    //home page

    .notescontainer{
        display: flex!important;
        flex-direction: column;
    }

    .image2{
        width: 42vw!important;
    }

    .notes{
        width: 42vw!important;
       
    }

    .carousel-inner{
        margin-left: 3rem!important;
    }

    .card{
        min-height: 20vh!important;
    }

    .roomcontainer{
        display: flex!important;
        flex-direction: column;
        margin-left: 0.5rem!important;
    }

    .slider{
        width: 90vw!important;
    }

    .paragraph2{
        width: 90vw!important;
    }

    .tourism{
        display: flex!important;
		flex-direction: column!important;
       

        .TourismSlider{
            margin-top: 0!important;

            .row2{
                display: flex!important;
                flex-direction: column!important;
                justify-content: center!important;
                margin: 0!important;
            }

            .imageleft{
                margin-bottom: 0!important;
                margin-left: 0.75rem!important;
                width: 90vw!important;

                &:hover{
                    transform: none!important;
                }
            }

            .paragraph3{
                margin: 0 0 0 0.75rem!important;
                width: 90vw!important;
            }

            .responsiveparagraph3{
                text-align: justify!important;
                padding: 0.5rem!important;
            }

            .responsivebutton{
                text-align: center!important;
            }
        }
    }
}

@media screen 
    and (min-device-width: 1000px) 
    and (max-device-width: 1200px)
    {

        .Header a span{
            display: none;
        }

        .notescontainer{
            display: flex!important;
            flex-direction: column!important;
            align-items: center;
            justify-content: center!important;
            padding: 0!important;
            margin: 0!important;
            max-width: 100vw!important;

            .image2{	
                width: 45vw!important;

                &:hover{
                    transform: none;
                }
            }

            .notes{
                width: 45vw!important;
            }
        }

        .paragraph2{
            padding-top: 1rem!important;
        }

        .testimonies{
            margin-left: 6rem;

        .carousel-indicator{
            display: none!important;
        }

        .carousel-control-prev{
            display: none!important;
        }

        .sr-only{
            display: none!important;
        }

        .carousel-control-next{
            display: none!important;
        }

        .carousel-inner{
            margin-left: 0!important;
        }

        .card{ 
            width: 78vw!important;
            min-height: 15vh!important;
        }

        .card-text{
            width: 78vw!important;
            font-size: 15px!important;
        }
    }
}