@import "./responsivemobile";
@import "./responsivetablet";
@import "./responsivebigsizescreen";
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@1,300&display=swap');
@import "bootstrap/dist/css/bootstrap.min.css";

.App{
	font-family: "Nunito";
	color: #423A36;
	max-width: 100%;
	overflow-x: hidden;
}

.subtitle{
	font-family: "Source Sans Pro";
	font-size: 50px;
	color: #CF9D6C;
}

.subtitlecustom{
	font-family: "Source Sans Pro";
	font-size: 35px;
	color: #CF9D6C;
}

.Header{

	.logotext{
		font-family: "Caveat";
		font-size: 4rem;
		color: #423A36;
		text-decoration: none;

		&:hover{
			color: #CF9D6C;
			transition: 0.3s;
		}
	}

	.links{
		margin-top: 1rem;
		text-decoration: none;
		color: #423A36;
		
		&:hover{
			color: #CF9D6C;
			transition: 0.3s;
		}
	}

	@media screen and (min-device-width: 1201px){
		.houseicon{
			width:72px;
			margin-top: 1rem;

			&:hover{
				scale: 1.1;
				transition: 0.3s;
			}
		}
	}

	.iconletter{
		margin-right: 0.3rem;

		&:hover{
			scale: 1.1;
			transition: 0.3s;
		}
	}

	.iconphone{
		margin-top: 0.3rem;
		margin-right: 0.5rem;	

		&:hover{
			scale: 1.1;
			transition: 0.3s;
		}
	}

	.phonenumber{
		font-size: 1.5rem;
		
		&:hover{
			font-size: 1.6rem;
			transition: 0.3s;
		}
	}

	.buttoncustom{
		text-decoration: none!important;
		background-color: white!important;
		border-color: #423A36!important;
		color: #423A36!important;

		&:hover{
			background-color: #CF9D6C!important;
			border-color: #423A36!important;
			transition: 0.5s!important;
		}

		&:focus,
		&:visited {
			text-decoration: none!important;
			box-shadow: none!important;
			outline: none!important;
		}
	}

	.colornav{
		background-color: #423A36;
	}

	.navbarlink{
		color: #c8c0b8;
		text-decoration: none;
		font-weight: bolder;
		font-size: 20px;
		
		&:hover{
			color: #CF9D6C;	
		}
	}

	.flag{
		pointer-events: none;
	}
}

.DisplayJumbotron{
	background-image: url(../assets/image1.webp);
  background-size: cover;
  background-position: center;
  min-height: 480px;
  max-width: 100vw;
  font-weight: bolder;
  font-size: xx-large;
}

.secondrow{
	padding-left: 2rem;
	padding-top: 0.5rem;
	background-color: #423A36!important;
}

.jumboline{
	margin-top: 0.2rem;
	font-size: 1.2rem;
	color: #c8c0b8;
}

.iconsjumbo{
	color: #c8c0b8;

	&:hover{
		color: #CF9D6C;
		scale: 1.1;
		transition: 0.3s;
	}
}

.lefttext{
	margin-right: 2rem;
}

//home page mainbody and subclasses

.mainbody{
	margin-left: 2.2rem;
	margin-right: 2rem;

	.logotext{
		font-family: "Caveat";
		font-size: 60px;
		color: #423A36;
		margin-top: 1rem;
		margin-bottom: 2rem;
	}

	.sublogotext{
		font-size: 40px;
	}

	.paragraph1{
		margin-top: 2%;
		padding-left: 2rem;
		padding-right: 2rem;
		text-align: justify;
		font-size: 20px;
	}

	.image2{
		height: 200px;
		width: 340px;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		&:hover{
			transform: scale(1.2);
		}
	}

	.notes{
		width: 20vw;
		height: 100%;
		background-color: #CF9D6C;
		color: #423A36;
		font-size: 20px;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}

	.stylenotes{
		font-size: 20px;
	}

	.iconsnotes{
		color: #423A36;
		text-decoration: none;
		
		&:hover{
			color: white;
			font-size: 22px;
			scale: 1.3;
			transition: 0.5s;
		}
	}


	.testimonies{
		margin-top: 5%;
		
		.carousel{
			width:100%;
		}
		.carousel-inner{
			margin-left: 25%;
			max-width: 100vw;
		}
		.card{
			border:none;
			width: 40rem;
			min-height: 40vh;
		}
		.card-text{
			font-family: "Rubik";
			font-size: 18px;
		}
		.dates{
			font-size: 15px;
			font-style: italic;
		}
		.iconstestimonies{
			text-decoration: none;
			color: #423A36;
			
			&:hover{
				color: #CF9D6C;
				scale: 1.3;
				transition: 0.5s;
			}
		}
	}
	
	.rooms{
		margin-top: 2%;
		margin-bottom: 5%;

		.slider{
			width: 50vw;

			.RoomsSlider{
				.thumbs-wrapper{
					display:none;
				}
	
				.thumb{
					display: none;
				}
			}
		}

		.paragraph2{
			padding-top: 3rem;
			padding-left: 2rem;
			padding-right: 2rem;
			text-align: justify;
			font-size: 20px;
			background-color: #c8c0b8;
			color: #423A36;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		}
	
		.buttoncustom2{
			background-color: #CF9D6C!important;
			border-color: #CF9D6C!important;
			
			&:hover{
				background-color: #c8c0b8!important;
				border-color: #CF9D6C!important;
				transition: 0.5s!important;

				.customlink2{
					color: #CF9D6C!important;
				}
			}
	
			&:focus,
			&:visited {
				text-decoration: none!important;
				box-shadow: none!important;
				outline: none!important;
			}
		}

		.customlink2{
			text-decoration: none!important;
			color: white!important;
		}
	}
	
	.tourism{
		margin-top: 5%;
		margin-bottom: 5%;

		.TourismSlider{
			margin-top: 2rem;
		}

		.imageleftwrapper{
			padding-left: 0rem!important;
			padding-right: 0rem!important;
		}
	
		.paragraph3{
			padding-top: 8rem;
			padding-bottom: 6rem;
			padding-left: 4rem;
			padding-right: 4rem;
			height: 592px;
			text-align: center;
			align-items: center;
			background-color: #CF9D6C;
			color: #423A36;
			font-size: 20px;
			border-radius: 5px;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		}

		.imageleft{
			border-radius: 5px;
			height: 592px;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			position: relative;
			
			&:hover{
				transform: scale(1.1);
			}
		}

		.custombutton3{
			background-color: #423A36!important;
			border-color: #423A36!important;
			
			&:hover{
				background-color: #CF9D6C!important;
				border-color: #423A36!important;
				transition: 0.5s!important;
				
				.customlink3{
					color: #423A36!important;
				}
			}
	
			&:focus,
			&:visited {
				text-decoration: none!important;
				box-shadow: none!important;
				outline: none!important;
			}
		}

		.customlink3{
			text-decoration: none!important;
			color: white!important;
		}
	}
}

//booking page

.Booking{
	margin-top: 2%;
	margin-bottom: 4%;

	.calendar{
		border-width:0;
	}

	.row{
		max-width: 100vw;
	}

	.iconsbooking{
		color: #CF9D6C;

		&:hover{
			scale: 1.4;
			color: #423A36!important;
			transition: 0.5s!important;
		}
	}

	.cardbooking{
		width: 30rem;
		min-height: 20vh;
		border-radius: 5px;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		&:hover{
			transform: scale(1.05);
			box-shadow: rgba(0, 0, 0, 0.75) 2px 5px 10px;
			transition: 0.3s;
		}
	}

	.price{
		font-size: 45px;
		font-weight: bold;
		color: #CF9D6C;
	}

	.custombutton4{
		text-decoration: none!important;
		background-color: #CF9D6C!important;
		border-color: #CF9D6C!important;
		
		&:hover{
			background-color: white!important;
			border-color: #CF9D6C!important;
			transition: 0.5s!important;
			.customlink4{
				color: #CF9D6C!important;
			}
		}

		&:focus,
		&:visited {
			text-decoration: none!important;
			box-shadow: none!important;
			outline: none!important;
		}
	}

	.customlink4{
		text-decoration: none!important;
		color: white!important;
	}
}

//contacts page

.Contacts{
	margin-top: 2%;
	margin-bottom: 4%;

	.row{
		max-width: 100vw;
	}

	.links{
		text-decoration: none;
		color: #CF9D6C;
		
		&:hover{
			color: #423A36;
			transition: 0.5s;
		}
	}

	.profile{
		border-radius: 50%;
	}

	.profiletitle{
		font-size: 25px;
	}

	.contactform{
		width: 70%;
	}

	.custombutton4{
		background-color: #CF9D6C!important;
		border-color: #CF9D6C!important;
		
		&:hover{
			background-color: white!important;
			border-color: #CF9D6C!important;
			color: #CF9D6C!important;
			transition: 0.5s!important;
		}

		&:focus,
		&:visited {
			text-decoration: none!important;
			box-shadow: none!important;
			outline: none!important;
		}
	}
}

//footer

.Footer{
	padding-top: 2rem;
	background-color: #423A36;
	color: #c8c0b8;

	.footerlogo{
		justify-content: center;
		margin-bottom: 2rem;
		font-family: "Caveat";
		font-size: 45px;
		color: #CF9D6C;
	}

	.footertitle{
		color: #CF9D6C;
		font-size: 25px;
		font-family: "Nunito";
	}

	.middlecolfooter{
		border-left: solid #c8c0b8;
		border-right: solid #c8c0b8;
		border-width: 1px;
	}

	.iconsfooter{
		color: #c8c0b8;
		
		&:hover{
			color: #CF9D6C;
			scale: 1.3;
			transition: 0.5s;
		}
	}

	.footermentions{
		margin-top: 2rem;
		padding-bottom: 1rem;
	}

	.footerlink{
		text-decoration: none;
		color: #c8c0b8;
		font-size: 16px;
		
		&:hover{
			color: #CF9D6C;
			font-size: 18px;
			transition: 0.5s;
		}
	}

	.footerphone{
		text-decoration: none;
		color: #c8c0b8;
		font-size: 25px;
		
		&:hover{
			color: #CF9D6C;
			font-size: 28px;
			transition: 0.5s;
		}
	}

	.responsivedisplayed{
		display: none;
	}
}

//page NotFound

.page_404{
	padding:40px 0; 
	background:#fff; 
	font-family: "Source Sans Pro";
}

.page_404  img{ 
	width:100%;
}

.four_zero_four_bg{
 	background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}
 
.four_zero_four_bg h1{
	font-size:80px;
}
 
.four_zero_four_bg h3{
	font-size:80px;
}
       
.link_404{      
  color: #fff!important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404{ 
	margin-top:-50px;
}

//mentions légales

.MentionsLegales{
	.mentionlink{
		text-decoration: none;
		color: #CF9D6C;
		
		&:hover{
			color: #c8c0b8;
			transition: 0.5s;
		}
	}
}